import { mainApi } from "./mainApi";

export const apisSettingApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getApisSettings: builder.query({
      query: (filters) => ({
        url: `/apisCountries/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "ApisSettings", id: "Search" }],
    }),
    updateApisSettings: builder.mutation({
      query: (apisSettingData) => ({
        url: `/apisCountries`,
        method: "PUT",
        body: apisSettingData,
      }),
      invalidatesTags: [{ type: "ApisSettings", id: "Search" }],
    }),
    addApisSettings: builder.mutation({
      query: (apisSettingData) => ({
        url: `/apisCountries`,
        method: "POST",
        body: apisSettingData,
      }),
      invalidatesTags: [{ type: "ApisSettings", id: "Search" }],
    }),
    deleteApisSetting: builder.mutation({
      query: (id) => ({
        url: `/apisCountries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ApisSettings", id: "Search" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApisSettingsQuery,
  useUpdateApisSettingsMutation,
  useAddApisSettingsMutation,
  useDeleteApisSettingMutation,
} = apisSettingApi;
