import { mainApi } from "./mainApi";

export const messagesTemplateApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessageTemplates: builder.query({
      query: (filters) => ({
        url: `/messageTemplates/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "MessageTemplates", id: "Search" }],
    }),
    getMessageTemplate: builder.query({
      query: (id) => ({
        url: `/messageTemplates/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "MessageTemplates", id: "Details" }],
    }),
    updateMessageTemplate: builder.mutation({
      query: (messageTemplateData) => ({
        url: `/messageTemplates`,
        method: "PUT",
        body: messageTemplateData,
      }),
      invalidatesTags: [{ type: "MessageTemplates", id: "Details" }],
    }),
    addMessageTemplate: builder.mutation({
      query: (messageTemplateData) => ({
        url: `/messageTemplates`,
        method: "POST",
        body: messageTemplateData,
      }),
      invalidatesTags: [{ type: "MessageTemplates", id: "Search" }],
    }),
    deleteMessageTemplate: builder.mutation({
      query: (id) => ({
        url: `/messageTemplates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "MessageTemplates", id: "Search" }],
    }),
    syncMessageTemplate: builder.mutation({
      query: (option) => ({
        url: `/messageTemplates/sync`,
        method: "POST",
        body: option,
      }),
      invalidatesTags: [{ type: "MessageTemplates", id: "Search" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMessageTemplatesQuery,
  useGetMessageTemplateQuery,
  useAddMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
  useSyncMessageTemplateMutation,
} = messagesTemplateApi;
