import NavitaireMessages from ".";

const NavitaireMessagesRoutes = [
  {
    path: "/navitaire-messages",
    index: true,
    element: <NavitaireMessages />,
  },
];

export default NavitaireMessagesRoutes;
