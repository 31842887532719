import { Dropdown } from "primereact/dropdown";
import {
  faBoxesPacking,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Eye, Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const messageTypes = ["ldm", "prl", "apis"];

const getMessageType = (type) => {
  switch (type) {
    case "ldm":
      return faBoxesPacking;
    case "prl":
      return faUsers;
    case "apis":
      return faUserTie;
  }
};

const messageTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(rowData.messageType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.messageType}
    </span>
  );
};

const messageTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={messageTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const messageTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const actionsBodyTemplate = (row, handleDeleteClick) => {
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <Link
          title="View"
          style={{ padding: ".75rem 1.25rem" }}
          to={`/sita-messages/messageTemplates/${row.id}`}
        >
          {" "}
          <Eye size={16} className="text-body" />
        </Link>
        <Link
          title="Edit"
          style={{ padding: ".75rem 1.25rem" }}
          to={`/sita-messages/messageTemplates/edit/${row.id}`}
        >
          {" "}
          <Edit size={16} className="text-body" />
        </Link>
        <Button.Ripple
          className="btn-icon"
          color="flat-danger"
          onClick={() => {
            handleDeleteClick({ ...row });
          }}
        >
          <Trash2 size={16} />
        </Button.Ripple>
      </div>
    </>
  );
};

const messageTemplatesColumns = (handleDeleteClick) => {
  return [
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Country",
      filter: true,
      field: "country",
      sortable: false,
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "Message Type",
      filter: true,
      field: "messageType",
      sortable: false,
      body: messageTypeBodyTemplate,
      filterElement: messageTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: (rowData) => actionsBodyTemplate(rowData, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default messageTemplatesColumns;
