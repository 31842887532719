import Table from "@src/components/DataTable/Table";
import {
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import {
  useGetNavitaireMessagesQuery,
  useBulkResendMessagesMutation,
} from "@src/redux/messages";
import { navitaireMessagesColumns } from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BulkResendMessagesModel from "@src/components/Messages/BulkResendMessagesModel";
import Loader from "@src/components/shared/Loader";
import { Send } from "react-feather";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { getMessageInitAdvancedFilter } from "@src/utility/Utils";
import PermissionButton from "@src/components/shared/PermissionButton";
import Select from "react-select";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import { TabView, TabPanel } from "primereact/tabview";

const NavitaireMessages = () => {
  const [bulkResendMessages, { isLoading: loadingResendMessages, error }] =
    useBulkResendMessagesMutation();
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: getMessageInitAdvancedFilter(),
    keyword: "",
  });

  const initialDtFilters = {};

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const [flightNo, setFlightNo] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading } = useGetNavitaireMessagesQuery(filters);

  const messageTypes = [
    { label: "ADL", value: "ADL" },
    { label: "PNL", value: "PNL" },
  ];

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFlightNo(""); // Clear flight number
    setMessageType(""); // Clear message type
    setDepartureDate(null);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: getMessageInitAdvancedFilter(),
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {/* <PermissionButton
          color="secondary"
          className="me-1"
          onClick={() => setVisible(true)}
          permission="sim.ldm.send"
        >
          <Send size={18} />
          &nbsp; Bulk Resend
        </PermissionButton> */}
      </div>
    );
  };

  const search = () => {
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: getMessageInitAdvancedFilter(),
      keyword: "",
      FlightNumber: flightNo,
      MessageType: messageType,
      FlightDate: departureDate ? format(departureDate, "yyyy-MM-dd") : null,
    });
  };

  const toggleCanvas = () => {
    setCanvasOpen(!canvasOpen);
  };

  return (
    <>
      {loadingResendMessages && <Loader />}
      <BreadCrumbs pageName="pnlList" pageTitle="PNL/ADL Message List" />
      <Card>
        <CardBody>
          <Row>
            <Col md={3}>
              <Select
                className={classnames("react-select")}
                placeholder={"Message Type"}
                classNamePrefix="select"
                options={messageTypes}
                isClearable
                menuPlacement="auto"
                value={messageTypes.find(
                  (option) => option.value === messageType
                )}
                onChange={(selectedOption) =>
                  setMessageType(selectedOption ? selectedOption.value : "")
                }
              />
            </Col>
            <Col md={3}>
              <Input
                placeholder="Flight Number"
                value={flightNo}
                onChange={(event) => setFlightNo(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <Flatpickr
                placeholder="Departure Date"
                className={`form-control`}
                value={departureDate}
                options={{
                  dateFormat: "Y-m-d",
                }}
                onChange={(date) => setDepartureDate(date[0])}
              />
            </Col>
            <Col md={3}>
              <Button
                color="primary"
                onClick={search}
                style={{ marginRight: "0.5rem" }}
              >
                Search
              </Button>
              <Button color="warning" onClick={clearFilters}>
                Reset
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={navitaireMessagesColumns(toggleCanvas)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Messages Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <BulkResendMessagesModel
        visible={visible}
        toggle={() => setVisible(false)}
        bulkResendMessagesFunc={bulkResendMessages}
        messageType={"ldm"}
        sendingMethods={["sita", "email"]}
      />

      <Offcanvas direction="end" isOpen={canvasOpen} toggle={toggleCanvas}>
        <OffcanvasHeader toggle={toggleCanvas}>Message</OffcanvasHeader>
        <OffcanvasBody className="my-auto mx-0 flex-grow-0">
          <TabView
            scrollable
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel
              rightIcon="fa-solid fa-building"
              leftIcon="pi pi-calendar mr-2"
              header={`Part 1`}
            ></TabPanel>
          </TabView>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default NavitaireMessages;
