import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faCircleExclamation,
  faBell,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const NotificationsColumns = () => {
  const types = [
    "messageSent",
    "messageFailed",
    "loadSheetReminder",
    "loadSheetForwarded",
  ];

  const getTypeText = (type) => {
    switch (type) {
      case "messageSent":
        return "Message Sent";
      case "messageFailed":
        return "Message Failed";
      case "loadSheetReminder":
        return "LoadSheet Reminder";
      case "loadSheetForwarded":
        return "LoadSheet Forwarded";
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case "messageSent":
        return faEnvelopeCircleCheck;
      case "messageFailed":
        return faCircleExclamation;
      case "loadSheetReminder":
        return faBell;
      case "loadSheetForwarded":
        return faForward;
    }
  };

  const formatDate = (value) => {
    return value.toLocaleDateString("en-UK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <span>
        <FontAwesomeIcon
          icon={getTypeIcon(rowData.type)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {getTypeText(rowData.type)}
      </span>
    );
  };

  const typeItemTemplate = (option) => {
    return (
      <span>
        <FontAwesomeIcon
          icon={getTypeIcon(option)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {getTypeText(option)}
      </span>
    );
  };

  const typeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={types}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={typeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const notifyTimeBodyTemplate = (rowData) => {
    return formatDate(new Date(rowData.notifyTime));
  };

  const notifyTimeFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) => {
          options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const actionsBodyTemplate = (row) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <Link to={`/sita-messages/notifications/${row.id}`}>
          {" "}
          <Eye className="font-medium-3 text-body" />
        </Link>
      </div>
    );
  };

  return [
    {
      header: "Notification Type",
      filter: true,
      field: "type",
      sortable: false,
      body: typeBodyTemplate,
      filterElement: typeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Recipients",
      filter: true,
      field: "to",
      sortable: false,
    },
    {
      header: "Notify Time",
      filter: true,
      field: "notifyTime",
      sortable: false,
      dataType: "date",
      filterField: "notifyTime.date",
      body: notifyTimeBodyTemplate,
      filterElement: notifyTimeFilterTemplate,
    },
    {
      header: "Subject",
      filter: true,
      field: "subject",
      sortable: false,
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: actionsBodyTemplate,
    },
  ];
};

export default NotificationsColumns;
