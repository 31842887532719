import { isRejectedWithValue } from "@reduxjs/toolkit";
import { ErrorToast } from "@src/components/shared/ErrorToast";

const showErrorToast = (title, errors) => {
  ErrorToast(
    <div>
      <h4 className="font-bold">{title}</h4>
      <ul className="list-disc pl-5">
        {errors.map((error, index) => (
          <li key={index}>{error.description}</li>
        ))}
      </ul>
    </div>
  );
};

export const rtkQueryErrorHandler = (storeAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!");
    if (!action.payload.data) return next(action);
    const errors = action.payload.data.errors || [
      { code: "Server_error", description: "Internal server error" },
    ];
    showErrorToast("", errors);
  }
  return next(action);
};
