import { Calendar } from "primereact/calendar";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const formatDate = (value) => {
  return value.toLocaleDateString("en-UK", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const stdutcBodyTemplate = (rowData) => {
  return formatDate(new Date(rowData.stdutc));
};

const stdutcFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) => {
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
      }}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
};

const actionsBodyTemplate = (row) => {
  return (
    <div className="d-flex" style={{ justifyContent: "space-between" }}>
      <Link to={`/sita-messages/flights/${row.id}`}>
        {" "}
        <Eye className="font-medium-3 text-body" />
      </Link>
    </div>
  );
};

export const flightsColumns = [
  {
    header: "Flight No",
    filter: true,
    field: "flightNo",
    sortable: false,
    dataType: "numeric",
  },
  {
    header: "Departure",
    filter: true,
    field: "departure",
    sortable: false,
  },
  {
    header: "Arrival",
    filter: true,
    field: "arrival",
    sortable: false,
  },
  {
    header: "Registration",
    filter: true,
    field: "registration",
    sortable: false,
  },
  {
    header: "STDUTC",
    filter: true,
    field: "stdutc",
    sortable: false,
    dataType: "date",
    body: stdutcBodyTemplate,
    filterElement: stdutcFilterTemplate,
    filterField: "stdutc.date",
  },
  {
    header: "Onward Pax",
    field: "onwardPassengersCount",
    sortable: false,
    dataType: "numeric",
  },
  {
    header: "Inbound Pax",
    field: "inboundPassengersCount",
    sortable: false,
    dataType: "numeric",
  },
  {
    header: "Actions",
    field: "action",
    filter: false,
    body: actionsBodyTemplate,
  },
];
