import Table from "@src/components/DataTable/Table";
import { Card, CardBody, Button } from "reactstrap";
import { useGetLoadSheetsQuery } from "@src/redux/loadsheets";
import loadSheetsColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useMatchFlightsWithLoadSheetsMutation } from "@src/redux/loadsheets";
import Loader from "@src/components/shared/Loader";
import { GitMerge } from "react-feather";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const LoadSheets = () => {
  const [
    matchFlightsWithLoadSheets,
    { isLoading: loadingMatchFlightsWithLoadSheets },
  ] = useMatchFlightsWithLoadSheetsMutation();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    subject: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    departureIcao: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    arrivalIcao: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    registration: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flightTime.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    isUploaded: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetLoadSheetsQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button.Ripple
          color="secondary"
          className="me-1"
          onClick={handleMatchFlights}
        >
          <GitMerge size={18} />
          &nbsp; Match Flights
        </Button.Ripple>
      </div>
    );
  };

  const handleMatchFlights = async () => {
    ConfirmSweetAlert({
      title: "Are you sure you want to match flights with load sheets?",
      icon: "question",
      confirmButtonText: "Match",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await matchFlightsWithLoadSheets()
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Flights have been successfully matched with load sheets!",
            });
          });
      },
    });
  };

  return (
    <>
      {loadingMatchFlightsWithLoadSheets && <Loader />}
      <BreadCrumbs pageName="loadSheetList" pageTitle="Load-Sheet List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={loadSheetsColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No LoadSheets Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default LoadSheets;
