import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import * as yup from "yup";
import { SelectInput, TextInput, ChipsInput } from "@src/components/inputs";
import {
  useAddMessageAddressMutation,
  useUpdateMessageAddressMutation,
} from "@src/redux/messageAddresses";
import {
  faEarthAsia,
  faJetFighterUp,
  faBuilding,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const MessageAddressModal = ({
  visible,
  toggle,
  editData,
  setEditData,
  mode,
}) => {
  const [updateMessageAddress] = useUpdateMessageAddressMutation();
  const [addMessageAddress] = useAddMessageAddressMutation();

  const messageTypeOptions = [
    { label: "LDM", value: "ldm" },
    { label: "APIS", value: "apis" },
    { label: "PRL", value: "prl" },
  ];
  const addressTypeOptions = [
    { label: "Sita", value: "sita" },
    { label: "Mail", value: "mail" },
    { label: "SitaMail", value: "sitaMail" },
  ];

  const messageAddressSchema = yup.object().shape({
    country: yup
      .string()
      .nullable()
      .test(
        "len",
        "Country must be 2 characters in length",
        (val) => val.length === 2 || val.length === 0
      ),
    station: yup
      .string()
      .nullable()
      .test(
        "len",
        "Station must be 3 characters in length",
        (val) => val.length === 3 || val.length === 0
      ),
    address: yup.lazy((value, { parent }) => {
      if (parent.addressType === "sita") {
        return yup
          .string()
          .test(
            "len",
            "Address must be 7 characters in length when Address Type equals Sita.",
            (val) => val.length === 7
          );
      } else {
        return yup
          .array()
          .of(yup.string().email("Must be a valid email"))
          .min(1, "At least one email is required");
      }
    }),
    flightNumber: yup
      .number()
      .typeError("Flight No must be a number")
      .nullable()
      .moreThan(0, "Flight No cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    messageType: yup.string().required(),
    addressType: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageAddressSchema),
    mode: "onSubmit",
    defaultValues: {
      country: "",
      station: "",
      address: [],
      flightNumber: "",
      messageType: "",
      addressType: "",
    },
  });

  const addressType = useWatch({
    control: formMethods.control,
    name: "addressType",
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          country: "",
          station: "",
          address: [],
          flightNumber: "",
          messageType: "",
          addressType: "",
        });
      } else {
        formMethods.reset({
          country: editData?.country || "",
          station: editData?.station || "",
          address: editData?.address ? editData?.address.split(";") : [],
          flightNumber: editData?.flightNumber || "",
          messageType: editData?.messageType || "",
          addressType: editData?.addressType || "",
        });
      }
    }
  }, [editData, visible, formMethods, mode]);

  const handleFormSubmit = async (formData) => {
    const formattedAddress =
      formData.addressType === "mail" || formData.addressType === "sitaMail"
        ? formData.address.join(";")
        : formData.address;

    if (mode === "update" && editData) {
      await updateMessageAddress({
        id: editData.id,
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageType: formData.messageType,
        addressType: formData.addressType,
      });
    } else {
      await addMessageAddress({
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageType: formData.messageType,
        addressType: formData.addressType,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Message Address
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Country"
                    name="country"
                    icon={faEarthAsia}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="FlightNo"
                    name="flightNumber"
                    icon={faJetFighterUp}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Station"
                    name="station"
                    icon={faBuilding}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    label="Message Type"
                    name="messageType"
                    options={messageTypeOptions}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  {addressType === "mail" || addressType === "sitaMail" ? (
                    <ChipsInput
                      label="Address"
                      name="address"
                      placeholder="Enter emails"
                    />
                  ) : (
                    <TextInput
                      twoLines
                      label="Address"
                      name="address"
                      icon={faLocationDot}
                    />
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    label="Address Type"
                    name="addressType"
                    options={addressTypeOptions}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default MessageAddressModal;
