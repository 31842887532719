import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { formatDateTime } from "@src/utility/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import StatusTag from "../../components/Messages/StatusTag";

const messageStatuses = ["pending", "sent", "fail"];

const directions = ["inbound", "outbound"];

const getDirectionIcon = (direction) => {
  switch (direction) {
    case "inbound":
      return faArrowRightToBracket;
    case "outbound":
      return faArrowRightFromBracket;
  }
};

const statusBodyTemplate = (rowData) => {
  return <StatusTag status={rowData.status} />;
};

const statusItemTemplate = (option) => {
  return <StatusTag status={option} />;
};

const statusFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={statusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const dateFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) => {
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
      }}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  );
};

const messageTimeBodyTemplate = (rowData) => {
  return formatDateTime(new Date(rowData.messageTime));
};

const directionBodyTemplate = (rowData) => {
  return (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getDirectionIcon(rowData.flightDirection)}
        className="font-medium-3"
      />{" "}
      {rowData.flightDirection}
    </span>
  );
};

const directionItemTemplate = (option) => {
  return (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getDirectionIcon(option)}
        className="font-medium-3"
      />{" "}
      {option}
    </span>
  );
};

const directionFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={directions}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={directionItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const actionsBodyTemplate = (row) => {
  return (
    <div className="d-flex" style={{ justifyContent: "space-between" }}>
      <Link to={`/sita-messages/apis-messages/${row.id}`}>
        {" "}
        <Eye className="font-medium-3 text-body" />
      </Link>
    </div>
  );
};

export const apisMessagesColumns = [
  {
    header: "Flight No",
    filter: true,
    field: "flightNo",
    sortable: false,
    filterField: "flight.flightNo",
    dataType: "numeric",
  },
  {
    header: "Departure",
    filter: true,
    field: "departure",
    sortable: false,
    filterField: "flight.departure",
  },
  {
    header: "Arrival",
    filter: true,
    field: "arrival",
    sortable: false,
    filterField: "flight.arrival",
  },
  {
    header: "Message Time",
    filter: true,
    field: "messageTime",
    sortable: false,
    dataType: "date",
    body: messageTimeBodyTemplate,
    filterElement: dateFilterTemplate,
    filterField: "messageTime.date",
  },
  {
    header: "Message Status",
    filter: true,
    field: "status",
    sortable: false,
    body: statusBodyTemplate,
    filterElement: statusFilterTemplate,
    showFilterMatchModes: false,
  },
  {
    header: "Direction",
    filter: true,
    field: "flightDirection",
    sortable: false,
    body: directionBodyTemplate,
    filterElement: directionFilterTemplate,
    showFilterMatchModes: false,
  },
  {
    header: "Actions",
    field: "action",
    filter: false,
    body: actionsBodyTemplate,
  },
];
