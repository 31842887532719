import React, { Fragment, memo, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import {
  useGetMessageTemplateQuery,
  useAddMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
} from "@src/redux/messageTemplates";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import {
  faEarthAsia,
  faHashtag,
  faMessage,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SelectInput } from "@src/components/inputs";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import BreadCrumbs from "../../components/shared/BreadCrumbs";

const MessageTemplateForm = () => {
  const navigate = useNavigate();
  const [updateMessageTemplate] = useUpdateMessageTemplateMutation();
  const [addMessageTemplate] = useAddMessageTemplateMutation();
  const { id } = useParams();
  const { data, isFetching, error } = useGetMessageTemplateQuery(id, {
    skip: !id,
  });

  const messageTemplateSchema = yup.object().shape({
    country: yup
      .string()
      .nullable()
      .test(
        "len",
        "Country must be 2 characters in length",
        (val) => val.length === 2 || val.length === 0
      ),
    station: yup
      .string()
      .nullable()
      .test(
        "len",
        "Station must be 3 characters in length",
        (val) => val.length === 3 || val.length === 0
      ),
    messageType: yup.string().required(),
    content: yup.string().required(),
    name: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageTemplateSchema),
    mode: "onSubmit",
    defaultValues: {
      country: "",
      station: "",
      messageType: "",
      content: "",
      name: "",
    },
  });
  const messageTypeOptions = [
    { label: "LDM", value: "ldm" },
    { label: "APIS", value: "apis" },
    { label: "PRL", value: "prl" },
  ];
  useEffect(() => {
    formMethods.reset({
      country: data?.country || "",
      station: data?.station || "",
      messageType: data?.messageType || "",
      content: data?.content || "",
      name: data?.name || "",
    });
  }, [data, formMethods]);

  const handleFormSubmit = async (formData) => {
    if (id) {
      await updateMessageTemplate({
        id,
        ...formData,
      })
        .unwrap()
        .then(() => {
          SuccessToast("Message template Updated Successfully!");
          navigate(`/sita-messages/messageTemplates/${id}`);
        })
        .catch((rejected) => {});
    } else {
      await addMessageTemplate(formData)
        .unwrap()
        .then(() => {
          SuccessToast("Message template Added Successfully!");
          navigate(`/sita-messages/messageTemplates`);
        })
        .catch((rejected) => {});
    }
  };

  if (isFetching) {
    return <Loader />;
  }
  return (
    <Fragment>
      <BreadCrumbs
        pageName="messageTemplateEdit"
        pageTitle="Edit Message Template"
      />
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">
            {id ? "Edit Message Template" : "Add Message Template"}
          </CardTitle>
        </CardHeader>
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <Col lg="6" md="6">
                    <div className="mb-1">
                      <TextInput
                        twoLines={true}
                        label="Template Name"
                        name="name"
                        icon={faHashtag}
                      />
                    </div>
                    <div className="mb-1">
                      <SelectInput
                        twoLines={true}
                        label="Message Type"
                        name="messageType"
                        icon={faHashtag}
                        options={messageTypeOptions}
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <div className="mb-1">
                      <TextInput
                        twoLines={true}
                        label="Country"
                        name="country"
                        icon={faEarthAsia}
                      />
                    </div>
                    <div className="mb-1">
                      <TextInput
                        twoLines={true}
                        label="Station"
                        name="station"
                        icon={faBuilding}
                      />
                    </div>
                  </Col>
                  <Col lg="12" md="12" className="mb-1">
                    <div className="mb-1">
                      <TextInput
                        twoLines={true}
                        label="Content"
                        name="content"
                        type="textarea"
                        rows="10"
                        icon={faMessage}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Save
              </Button>
              <Button
                type="button"
                color="warning"
                className="mx-1"
                onClick={() => {
                  navigate(`/sita-messages/messageTemplates`);
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </FormProvider>
      </Card>
    </Fragment>
  );
};

export default memo(MessageTemplateForm);
