import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Col,
  Row,
  Alert,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import * as yup from "yup";
import { SelectInput, TextInput, ChipsInput } from "@src/components/inputs";
import {
  useAddMessageAddressMutation,
  useUpdateMessageAddressMutation,
} from "@src/redux/messageAddresses";
import { useLazyListMessageTypesQuery } from "@src/redux/MessageTypes";
import {
  faEarthAsia,
  faJetFighterUp,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

const MessageAddressModal = ({
  visible,
  toggle,
  editData,
  setEditData,
  mode,
}) => {
  const [updateMessageAddress] = useUpdateMessageAddressMutation();
  const [addMessageAddress] = useAddMessageAddressMutation();
  const [listMessageTypes] = useLazyListMessageTypesQuery();

  const [messageTypes, setMessageTypes] = useState([]);

  const addressTypeOptions = [
    { label: "Sita", value: "sita" },
    { label: "Mail", value: "mail" },
    { label: "SitaMail", value: "sitaMail" },
  ];

  const messageAddressSchema = yup
    .object()
    .shape({
      country: yup
        .string()
        .nullable()
        .test(
          "len",
          "Country must be 2 characters in length",
          (val) => !val || val.length === 2
        ),
      station: yup
        .string()
        .nullable()
        .test(
          "len",
          "Station must be 3 characters in length",
          (val) => !val || val.length === 3
        ),
      flightNumber: yup
        .number()
        .typeError("Flight No must be a number")
        .nullable()
        .moreThan(0, "Flight No cannot be negative")
        .transform((_, val) => (val !== "" ? Number(val) : null)),
      address: yup.lazy((value, { parent }) => {
        if (parent.addressType === "sita") {
          return yup
            .array()
            .of(
              yup
                .string()
                .test(
                  "len",
                  "SITA address must be 7 characters in length",
                  (val) => val?.length === 7
                )
            )
            .min(1, "At least one SITA address is required");
        } else {
          return yup
            .array()
            .of(yup.string().email("Must be a valid email"))
            .min(1, "At least one email is required");
        }
      }),
      messageTypeId: yup.string().required("Message type is required"),
      addressType: yup.string().required("Address type is required"),
    })
    .test(
      "at-least-one-identifier",
      "At least one of Country, Station, or Flight No must be filled",
      function (values) {
        const { country, station, flightNumber } = values;
        if (!country && !station && !flightNumber) {
          return this.createError({
            path: "form",
            message:
              "At least one of Country, Station, or Flight No must be filled",
          });
        }
        return true;
      }
    );

  const formMethods = useForm({
    resolver: yupResolver(messageAddressSchema),
    mode: "onChange",
    defaultValues: {
      country: "",
      station: "",
      address: [],
      flightNumber: "",
      messageTypeId: "",
      addressType: "",
    },
  });

  const addressType = useWatch({
    control: formMethods.control,
    name: "addressType",
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          country: "",
          station: "",
          address: [],
          flightNumber: "",
          messageTypeId: "",
          addressType: "",
        });
      } else {
        const addresses = editData?.address
          ? editData.address.split(";").filter(Boolean)
          : [];

        formMethods.setValue("addressType", editData?.addressType || "");

        formMethods.setValue("country", editData?.country || "");
        formMethods.setValue("station", editData?.station || "");
        formMethods.setValue("address", addresses);
        formMethods.setValue("flightNumber", editData?.flightNumber || "");
        formMethods.setValue("messageTypeId", editData?.messageTypeId || "");
      }
    }
  }, [editData, visible, formMethods, mode]);

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (name === "addressType") {
        formMethods.setValue("address", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [formMethods]);

  useEffect(() => {
    const fetchMessagesList = async () => {
      const list = await listMessageTypes().unwrap();
      setMessageTypes(list);
    };
    fetchMessagesList();
  }, []);

  const handleFormSubmit = async (formData) => {
    const formattedAddress = formData.address.join(";");

    if (mode === "update" && editData) {
      await updateMessageAddress({
        id: editData.id,
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageTypeId: formData.messageTypeId,
        addressType: formData.addressType,
      });
    } else {
      await addMessageAddress({
        country: formData.country,
        station: formData.station,
        address: formattedAddress,
        flightNumber: formData.flightNumber,
        messageTypeId: formData.messageTypeId,
        addressType: formData.addressType,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="lg" className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Message Address
          </ModalHeader>
          <ModalBody>
            {formMethods.formState.errors.form && (
              // <div className="alert alert-danger mb-2">
              // </div>
              <Alert color="danger p-1 mb-1">
                {formMethods.formState.errors.form.message}
              </Alert>
            )}
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Country"
                    name="country"
                    icon={faEarthAsia}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="FlightNo"
                    name="flightNumber"
                    icon={faJetFighterUp}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <TextInput
                    twoLines
                    label="Station"
                    name="station"
                    icon={faBuilding}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    optionKey="id"
                    optionLabel="code"
                    label="Message Type"
                    name="messageTypeId"
                    options={messageTypes}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-2">
                  <SelectInput
                    twoLines
                    label="Address Type"
                    name="addressType"
                    options={addressTypeOptions}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-2">
                  <ChipsInput
                    twoLines
                    label="Address"
                    name="address"
                    placeholder={
                      addressType === "sita"
                        ? "Enter SITA address (7 characters)"
                        : "Enter email address"
                    }
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>
            <Button color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default MessageAddressModal;
