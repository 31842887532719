import toast from "react-hot-toast";

export const ErrorToast = (message = "Error occurred.", duration = 5000) => {
  toast.error(message, {
    style: {
      border: "1px solid #ff4b4b",
      padding: "16px",
      color: "#495057",
    },
    duration,
  });
};
