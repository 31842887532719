import { mainApi } from "./mainApi";

export const messageTypeApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessageTypes: builder.query({
      query: (filters) => ({
        url: `/messageTypes/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "MessageTypes", id: "LIST" }],
    }),
    getMessageType: builder.query({
      query: (id) => ({
        url: `/messageTypes/${id}`,
        method: "GET",
      }),
    }),
    listMessageTypes: builder.query({
      query: (id) => ({
        url: `/messageTypes/list`,
        method: "GET",
      }),
    }),
    updateMessageTypes: builder.mutation({
      query: (messageTypeData) => ({
        url: `/messageTypes`,
        method: "PUT",
        body: messageTypeData,
      }),
      invalidatesTags: [{ type: "MessageTypes", id: "LIST" }],
    }),
    configureMessageTypes: builder.mutation({
      query: (messageTypeData) => ({
        url: `/messageTypes/configure`,
        method: "PUT",
        body: messageTypeData,
      }),
      invalidatesTags: [{ type: "MessageTypes", id: "LIST" }],
    }),
    addMessageTypes: builder.mutation({
      query: (messageTypeData) => ({
        url: `/messageTypes`,
        method: "POST",
        body: messageTypeData,
      }),
      invalidatesTags: [{ type: "MessageTypes", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMessageTypesQuery,
  useLazyGetMessageTypeQuery,
  useUpdateMessageTypesMutation,
  useAddMessageTypesMutation,
  useConfigureMessageTypesMutation,
  useLazyListMessageTypesQuery,
  useListMessageTypesQuery,
} = messageTypeApi;
