import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import {
  useAddMessageTypesMutation,
  useUpdateMessageTypesMutation,
} from "@src/redux/MessageTypes";
import {
  faPen,
  faHashtag,
  faCode,
  faComment,
} from "@fortawesome/free-solid-svg-icons";

const MessageTypeModal = ({ visible, toggle, editData, setEditData, mode }) => {
  const [updateMessageTypes] = useUpdateMessageTypesMutation();
  const [addMessageTypes] = useAddMessageTypesMutation();

  const messageTypesSchema = yup.object().shape({
    code: yup.string().required(),
    name: yup
      .string()
      .nullable()
      .test(
        "len",
        "Name must be 2 characters at least in length",
        (val) => val.length >= 2
      ),
    description: yup.string().nullable(),
    icon: yup.string().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageTypesSchema),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      code: "",
      icon: "",
      description: "",
    },
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          name: "",
          code: "",
          description: "",
          icon: "",
        });
      } else {
        formMethods.reset({
          name: editData?.name || "",
          code: editData?.code || "",
          description: editData?.description || "",
          icon: editData?.icon || "",
        });
      }
    }
  }, [editData, visible, formMethods, mode]);

  const handleFormSubmit = async (formData) => {
    if (mode === "update" && editData) {
      await updateMessageTypes({
        id: editData.id,
        name: formData.name,
        code: formData.code,
        icon: formData.icon,
        description: formData.description,
      });
    } else {
      await addMessageTypes({
        name: formData.name,
        code: formData.code,
        icon: formData.icon,
        description: formData.description,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Message Types
          </ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <TextInput label="Code" name="code" icon={faCode} />
            </div>
            <div className="mb-2">
              <TextInput label="Name" name="name" icon={faPen} />
            </div>
            <div className="mb-2">
              <TextInput label="Icon" name="icon" icon={faHashtag} />
            </div>
            <div className="mb-2">
              <TextInput
                label="Description"
                name="description"
                type="textarea"
                rows="3"
                icon={faComment}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default MessageTypeModal;
