import React, { Fragment, memo, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Form,
  CardFooter,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useUploadLoadSheetMutation } from "@src/redux/loadsheets";
import Loader from "@src/components/shared/Loader";
import { faCalendar, faJetFighterUp } from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import CalendarInput from "@src/components/inputs/CalendarInput";
import { format } from "date-fns";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const Upload = () => {
  const navigate = useNavigate();
  const [uploadLoadSheet, { isLoading }] = useUploadLoadSheetMutation();
  const uploadLoadSheetSchema = yup.object().shape({
    start: yup.date().required(),
    end: yup
      .date()
      .required()
      .min(yup.ref("start"), "end Date can't be before start Date")
      .test(
        "max-24-hours",
        "The maximum difference between start and end dates is 24 hours",
        function (value) {
          const { start } = this.parent;
          if (start && value) {
            const diff = Math.abs(new Date(value) - new Date(start));
            const diffHours = diff / (1000 * 60 * 60);
            return diffHours <= 24;
          }
          return true;
        }
      ),
    flightNo: yup
      .number()
      .typeError("Flight No must be a number")
      .nullable()
      .moreThan(0, "Flight No can not be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
  });

  const formMethods = useForm({
    resolver: yupResolver(uploadLoadSheetSchema),
    mode: "onSubmit",
    defaultValues: {
      start: "",
      end: "",
      flightNo: "",
    },
  });

  useEffect(() => {
    formMethods.reset({
      start: "",
      end: "",
      flightNo: "",
    });
  }, [formMethods]);

  const handleFormSubmit = async (formData) => {
    await uploadLoadSheet({
      ReceivedDateFrom: format(formData.start, "yyyy-MM-dd'T'HH:mm:ss"),
      ReceivedDateTo: format(formData.end, "yyyy-MM-dd'T'HH:mm:ss"),
      FlightNumber: padFlightNumber(formData.flightNo),
    })
      .unwrap()
      .then(() => {
        SuccessToast("Load-Sheet uploaded successfully!");
      })
      .catch((rejected) => {});
  };
  const padFlightNumber = (number) => {
    return String(number).padStart(3, "0");
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <BreadCrumbs pageName="loadSheetUpload" pageTitle="Upload Load-Sheets" />
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Upload Load-Sheet</CardTitle>
        </CardHeader>
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <Col lg="6" md="6">
                    <div className="mb-1">
                      <CalendarInput
                        label="Start Date"
                        name="start"
                        icon={faCalendar}
                        options={{
                          enableTime: true,
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <CalendarInput
                        label="End Date"
                        name="end"
                        icon={faCalendar}
                        options={{
                          enableTime: true,
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <TextInput
                        label="FlightNo"
                        name="flightNo"
                        icon={faJetFighterUp}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Upload
              </Button>
              <Button
                type="button"
                color="warning"
                className="mx-1"
                onClick={() => {
                  navigate(`/sita-messages/load-sheets`);
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </FormProvider>
      </Card>
    </Fragment>
  );
};

export default memo(Upload);
