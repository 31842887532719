import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { formatDateToString } from "@src/utility/Utils";
import {
  ChipsInput,
  CalendarInput,
  CheckboxInput,
} from "@src/components/inputs";
import { AlertCircle } from "react-feather";

const FetchFlightsModel = ({ visible, toggle, fetchFlightsFunc }) => {
  const fetchFlightSchema = yup
    .object()
    .shape({
      start: yup.date().required(),
      end: yup
        .date()
        .required()
        .min(yup.ref("start"), "End date can't be before start date")
        .test(
          "max-48-hours",
          "The maximum difference between start and end dates is 48 hours",
          function (value) {
            const { start } = this.parent;
            if (start && value) {
              const diff = Math.abs(new Date(value) - new Date(start));
              const diffHours = diff / (1000 * 60 * 60);
              return diffHours <= 48;
            }
            return true;
          }
        ),
      withPax: yup.boolean(),
      withCrews: yup.boolean(),
      flightNumbers: yup
        .array()
        .of(
          yup
            .string()
            .matches(
              /^\d{1,4}$/,
              "Each flight number must be a number up to 4 digits"
            )
        )
        .nullable(),
    })
    .test(
      "at-least-one-checked",
      'At least one of "Fetch With Pax" or "Fetch With Crews" must be checked',
      function (value) {
        const { withPax, withCrews } = value;
        if (!withPax && !withCrews) {
          return this.createError({
            path: "form",
            message:
              'At least one of "Fetch With Pax" or "Fetch With Crews" must be checked',
          });
        }
        return true;
      }
    );

  const formMethods = useForm({
    resolver: yupResolver(fetchFlightSchema),
    mode: "onSubmit",
    defaultValues: {
      start: "",
      end: "",
      withPax: false,
      withCrews: false,
      flightNumbers: [],
    },
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        start: "",
        end: "",
        withPax: false,
        withCrews: false,
        flightNumbers: [],
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await fetchFlightsFunc({
      start: formatDateToString(formData.start),
      end: formatDateToString(formData.end),
      withPax: formData.withPax,
      withCrews: formData.withCrews,
      flightNumbers: formData.flightNumbers,
    })
      .unwrap()
      .then(() => {
        SuccessToast("Flights fetched successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Fetch Flights</ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <CalendarInput
                label="Start Date"
                name="start"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <CalendarInput
                label="End Date"
                name="end"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <ChipsInput
                label="Flight Numbers"
                name="flightNumbers"
                color="info"
                infoMessage=" - max 4 digits each"
              />
              {errors.flightNumbers && (
                <Alert color="danger">
                  <div className="alert-body">
                    <AlertCircle size={14} />
                    <span className="align-middle ms-50">
                      Each flight number must be a number up to 4 digits
                    </span>
                  </div>
                </Alert>
              )}
            </div>
            <div className="mb-2">
              <CheckboxInput label="Fetch With Pax" name="withPax" />
              <CheckboxInput label="Fetch With Crews" name="withCrews" />
              {formMethods.formState.errors.form && (
                <FormFeedback className="d-block">
                  {formMethods.formState.errors.form.message}
                </FormFeedback>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Fetch
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default FetchFlightsModel;
