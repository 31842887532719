import React, { useEffect } from "react";
import CalendarInput from "@src/components/inputs/CalendarInput";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { MultiSelectInput } from "@src/components/inputs";

const messageStatusOptions = [
  { label: "Pending", value: 0 },
  { label: "Sent", value: 2 },
];
const sendingMethodsOptions = [
  { label: "Sita", value: 0 },
  { label: "Email", value: 1 },
];

const BulkResendMessagesModel = ({
  visible,
  toggle,
  bulkResendMessagesFunc,
  messageType,
  sendingMethods = [],
}) => {
  const bulkResendMessagesSchema = yup.object().shape({
    from: yup.date().required(),
    to: yup
      .date()
      .required()
      .min(yup.ref("from"), "to Date can't be before from Date")
      .test(
        "max-48-hours",
        "The maximum difference between from and to dates is 48 hours",
        function (value) {
          const { from } = this.parent;
          if (from && value) {
            const diff = Math.abs(new Date(value) - new Date(from));
            const diffHours = diff / (1000 * 60 * 60);
            return diffHours <= 48;
          }
          return true;
        }
      ),
    MessageStatuses: yup.array().of(yup.string()).notRequired(),
    sendingMethods: yup.array().of(yup.string()).notRequired(),
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        from: "",
        to: "",
        MessageStatuses: [],
        sendingMethods: [],
      });
    }
  }, [visible, formMethods]);

  const formMethods = useForm({
    resolver: yupResolver(bulkResendMessagesSchema),
    mode: "onSubmit",
    defaultValues: {
      from: "",
      to: "",
      MessageStatuses: [],
      sendingMethods: [],
    },
  });

  const handleFormSubmit = async (formData) => {
    const sendingMethodsToUse =
      sendingMethods.length > 1 ? formData.sendingMethods : ["sita"];

    await bulkResendMessagesFunc({
      from: formData.from,
      to: formData.to,
      MessageStatuses: formData.MessageStatuses,
      MessageTypes: [messageType],
      SendingMethods: sendingMethodsToUse,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Messages sent successfully!",
        });
      });
    toggle(false);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Resend Bulk Messages</ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <CalendarInput
                label="From Date"
                name="from"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <CalendarInput
                label="To Date"
                name="to"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <MultiSelectInput
                placeholder="Select (Optional)"
                label="Message Status"
                name="MessageStatuses"
                options={messageStatusOptions}
              />
            </div>
            {sendingMethods.length > 1 && (
              <div className="mb-2">
                <MultiSelectInput
                  placeholder="Select (Optional)"
                  label="Sending Methods"
                  name="sendingMethods"
                  options={sendingMethodsOptions}
                />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Resend
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default BulkResendMessagesModel;
