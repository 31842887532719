import StatusTag from "../../components/Messages/StatusTag";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { formatDateTime } from "@src/utility/Utils";
import { Eye, AlertCircle } from "react-feather";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import IconButton from "@src/components/shared/IconButton";

const messageStatuses = ["pending", "sent", "fail"];
const prlMessagesColumns = (handleShowErrorsClick) => {
  const statusBodyTemplate = (rowData) => {
    return <StatusTag status={rowData.status} />;
  };

  const statusItemTemplate = (option) => {
    return <StatusTag status={option} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={messageStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) => {
          options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const messageTimeBodyTemplate = (rowData) => {
    return formatDateTime(new Date(rowData.messageTime));
  };

  const actionsBodyTemplate = (row, handleShowErrorsClick) => {
    return (
      <div className="d-flex justify-content-center">
        <Link to={`/sita-messages/prl-messages/${row.flightId}/${row.partNo}`}>
          {" "}
          <Eye className="font-medium-3 text-body" />
        </Link>
        {row.status == "fail" ? (
          <IconButton
            className="ms-2"
            icon={<AlertCircle className="font-medium-3 text-body" />}
            tooltip="Show Errors"
            tooltipOptions={{ position: "left" }}
            onClick={() => {
              handleShowErrorsClick({ ...row });
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return [
    {
      header: "Flight No",
      filter: true,
      field: "flightNo",
      sortable: false,
      filterField: "flight.flightNo",
      dataType: "numeric",
    },
    {
      header: "Departure",
      filter: true,
      field: "departure",
      sortable: false,
      filterField: "flight.departure",
    },
    {
      header: "Arrival",
      filter: true,
      field: "arrival",
      sortable: false,
      filterField: "flight.arrival",
    },
    {
      header: "Message Time",
      filter: true,
      field: "messageTime",
      sortable: false,
      dataType: "date",
      body: messageTimeBodyTemplate,
      filterElement: dateFilterTemplate,
      filterField: "messageTime.date",
    },
    {
      header: "Message Status",
      filter: true,
      field: "status",
      sortable: false,
      body: statusBodyTemplate,
      filterElement: statusFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Part No",
      filter: true,
      field: "partNo",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: (row) => actionsBodyTemplate(row, handleShowErrorsClick),
    },
  ];
};

export default prlMessagesColumns;
