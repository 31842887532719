import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetMessageTypesQuery } from "@src/redux/MessageTypes";
import messageTypesColumns from "./Columns";
import { useState } from "react";
import MessageTypeModal from "./MessageTypeModal";
import Configurations from "./configurations";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const ApisSettings = () => {
  const [editData, setEditData] = useState(null);
  const [messageTypeId, setMessageTypeId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [displayConfigs, setDisplayConfigs] = useState(false);
  const [mode, setMode] = useState("add");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetMessageTypesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleAddClick = () => {
    setEditData(null);
    setMode("add");
    setVisible(true);
  };

  const handleEditClick = (item) => {
    setEditData(item);
    setMode("update");
    setVisible(true);
  };

  const configureMessageType = (messageTypeId) => {
    setDisplayConfigs(true);
    setMessageTypeId(messageTypeId);
  };

  return (
    <>
      <BreadCrumbs pageName="messageTypesList" pageTitle="Message Types List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={messageTypesColumns(handleEditClick, configureMessageType)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Message Types Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={handleAddClick}
          />
        </CardBody>
        <MessageTypeModal
          visible={visible}
          toggle={() => setVisible(false)}
          editData={editData}
          setEditData={setEditData}
          mode={mode}
        />
        <Configurations
          messageTypeId={messageTypeId}
          visible={displayConfigs}
          toggle={() => setDisplayConfigs(false)}
        />
      </Card>
    </>
  );
};

export default ApisSettings;
