import React, { useState } from "react";
import { Send } from "react-feather";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import Loader from "@src/components/shared/Loader";
import { useSendNavitaireMessageMutation } from "@src/redux/messages";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from "reactstrap";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RadioInput, ChipsInput } from "@src/components/inputs";
import PermissionButton from "@src/components/shared/PermissionButton";
import PermissionIconButton from "@src/components/shared/PermissionIconButton";

const SendNavitaireMessage = ({ messageId }) => {
  const [sendNavitaireMessage, { isLoading }] =
    useSendNavitaireMessageMutation();
  const [modalVisible, setModalVisible] = useState(false);

  const sendMessageSchema = yup.object().shape({
    emails: yup
      .array()
      .of(yup.string().email("Must be a valid email"))
      .min(1, "At least one email is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(sendMessageSchema),
    mode: "onChange",
    defaultValues: {
      emails: [],
    },
  });

  const toggleModal = () => {
    setModalVisible(!modalVisible);
    if (!modalVisible) {
      formMethods.reset();
    }
  };

  const handleSend = async (formData) => {
    const payload = {
      id: messageId,
      emails: formData.emails,
    };

    await sendNavitaireMessage(payload).unwrap();
    SuccessToast("Messages sent Successfully!");
    toggleModal();
  };

  return (
    <>
      {isLoading && <Loader />}

      <PermissionIconButton
        icon={<Send className="font-medium-3 text-body" />}
        tooltip="Send by Email"
        tooltipOptions={{ position: "top" }}
        onClick={toggleModal}
        permission="sim.pnl.send"
      />

      <Modal
        isOpen={modalVisible}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleSend)}>
            <ModalHeader toggle={toggleModal}>Send Message</ModalHeader>
            <ModalBody>
              <div className="mb-2">
                <ChipsInput
                  name="emails"
                  label="Emails"
                  placeholder="Enter email and press enter"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <PermissionButton
                loading={isLoading}
                type="submit"
                color="primary"
                //permission="sim.pnl.send"
              >
                Save
              </PermissionButton>
              <Button type="button" color="warning" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default SendNavitaireMessage;
