import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ModalFooter,
  Button,
  Alert,
} from "reactstrap";
import { ReadonlyInput } from "@src/components/inputs";
import CopyToClipboard from "@src/components/copyToClipboard";
import { formatDateTime } from "@src/utility/Utils";

const ErrorModal = ({ isOpen, toggle, errors }) => {
  const [selectedErrorIndex, setSelectedErrorIndex] = useState(0);

  const handleSelectError = (index) => {
    setSelectedErrorIndex(index);
  };

  const selectedError = errors[selectedErrorIndex];

  const extractErrorReason = (errorText) => {
    const lines = errorText.split("\n");
    const errorLine = lines.find((line) => line.trim().startsWith("Error"));
    return errorLine || "No error Reason found.";
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="p-fluid">
      <ModalHeader toggle={toggle}>Error List Details</ModalHeader>
      <ModalBody>
        {errors.length > 0 ? (
          <Row>
            <Col lg="3">
              <ListGroup className="list-group-errors">
                {errors.map((error, index) => (
                  <ListGroupItem
                    key={index}
                    active={index === selectedErrorIndex}
                    onClick={() => handleSelectError(index)}
                    action
                    style={{ cursor: "pointer" }}
                  >
                    {`${formatDateTime(new Date(error.errorTime))}`}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
            <Col lg="9">
              {selectedError ? (
                <>
                  <Alert color="danger p-1 mb-1">
                    <span>
                      {`Error Reason: ${extractErrorReason(
                        selectedError.errorText
                      )}`}
                    </span>
                  </Alert>
                  <ReadonlyInput
                    name={`ErrorText-${selectedErrorIndex}`}
                    type="textarea"
                    rows="15"
                    defaultValue={selectedError.errorText}
                  />
                  <CopyToClipboard
                    value={selectedError.errorText}
                    defaultMsg="Click to copy"
                    successMsg="Copied to clipboard!"
                  />
                </>
              ) : (
                <p>Select an error to view its details.</p>
              )}
            </Col>
          </Row>
        ) : (
          <p>No errors to display.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="warning" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
