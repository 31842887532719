import { mainApi } from "./mainApi";

export const flightsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFlights: builder.query({
      query: (filters) => ({
        url: `/flights/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "Flights", id: "Search" }],
    }),
    getFlight: builder.query({
      query: (id) => ({
        url: `/flights/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "Flights", id: "Details" }],
    }),
    fetchFlights: builder.mutation({
      query: (duration) => ({
        url: `/flights/fetch`,
        method: "POST",
        body: duration,
      }),
      invalidatesTags: [{ type: "Flights", id: "Search" }],
    }),
    updateIcaoStations: builder.mutation({
      query: () => ({
        url: `/flights/update-icao-stations`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Flights", id: "Search" }],
    }),
    generateMessage: builder.mutation({
      query: (data) => ({
        url: `/flights/generate-message`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Flights", id: "Details" }],
    }),
    exportFlights: builder.mutation({
      query: (filters) => ({
        url: `/flights/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFlightsQuery,
  useGetFlightQuery,
  useFetchFlightsMutation,
  useUpdateIcaoStationsMutation,
  useGenerateMessageMutation,
  useExportFlightsMutation,
} = flightsApi;
