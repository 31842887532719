import { Dropdown } from "primereact/dropdown";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { Edit, Trash2 } from "react-feather";

const ActivationStatuses = [
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

const getActivationStatusIcon = (isActive) =>
  isActive === "true" ? faCircleCheck : faCircleXmark;

const getActivationStatusClass = (isActive) =>
  isActive === "true" ? "text-success" : "text-danger";

const ActivationStatusBodyTemplate = ({ isActive }) => (
  <span className="text-capitalize">
    <FontAwesomeIcon
      icon={getActivationStatusIcon(isActive.toString())}
      className={getActivationStatusClass(isActive.toString())}
      style={{ fontSize: "1.3rem" }}
      aria-hidden="true"
    />
    <span className="sr-only">
      {isActive === "true" ? "Active" : "Inactive"}
    </span>
  </span>
);

const ActivationStatusFilterTemplate = (options) => (
  <Dropdown
    value={options.value}
    options={ActivationStatuses}
    onChange={(e) => options.filterCallback(e.value, options.index)}
    itemTemplate={ActivationStatusItemTemplate}
    placeholder="Select One"
    className="p-column-filter"
    showClear
  />
);

const ActivationStatusItemTemplate = ({ label, value }) => (
  <span className="text-capitalize">
    <FontAwesomeIcon
      icon={getActivationStatusIcon(value)}
      className={getActivationStatusClass(value)}
      style={{ fontSize: "1.3rem" }}
    />{" "}
    {label}
  </span>
);

const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
  return (
    <div className="justify-content-center flex-column text-center">
      <Button.Ripple
        className="btn-icon"
        color="flat-dark"
        onClick={() => {
          handleEditClick({ ...rowData });
        }}
      >
        <Edit size={16} />
      </Button.Ripple>
      <Button.Ripple
        className="btn-icon"
        color="flat-danger"
        onClick={() => {
          handleDeleteClick({ ...rowData });
        }}
      >
        <Trash2 size={16} />
      </Button.Ripple>
    </div>
  );
};

const apisSettingsColumns = (handleEditClick, handleDeleteClick) => {
  return [
    {
      header: "Country",
      filter: true,
      field: "country",
      sortable: false,
    },
    {
      header: "Recipient Code",
      filter: true,
      field: "recipientCode",
      sortable: false,
    },
    {
      header: "Is Active",
      filter: true,
      field: "isActive",
      sortable: false,
      body: ActivationStatusBodyTemplate,
      filterElement: ActivationStatusFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default apisSettingsColumns;
