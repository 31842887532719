import React from "react";
import { Tag } from "primereact/tag";

const getSeverity = (status) => {
  switch (status) {
    case "pending":
      return "warning";
    case "sent":
      return "success";
    case "fail":
      return "danger";
    default:
      return "info";
  }
};

const StatusTag = ({ status, value }) => {
  return (
    <Tag
      value={value ?? status}
      severity={getSeverity(status)}
      className="text-capitalize"
    />
  );
};

export default StatusTag;
