import Table from "@src/components/DataTable/Table";
import { Card, CardBody, Button } from "reactstrap";
import { useGetNotificationsQuery } from "@src/redux/notifications";
import notificationsColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { File } from "react-feather";
import { useExportNotificationsMutation } from "@src/redux/notifications";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const Notifications = () => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    to: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "notifyTime.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    subject: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const { data, isLoading } = useGetNotificationsQuery(filters);
  const [exportNotifications] = useExportNotificationsMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button.Ripple
          color="secondary"
          className="me-1"
          onClick={handleExport}
        >
          <File size={18} />
          &nbsp; Export
        </Button.Ripple>
      </div>
    );
  };

  const handleExport = async () => {
    if (filters.advancedFilter == null) {
      openSweetAlert({
        title: "Please select filteration before export the notification.",
      });
      return;
    }
    ConfirmSweetAlert({
      title: "Are you sure , you need to export notifications?",
      icon: "question",
      confirmButtonText: "Export",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        const response = await exportNotifications(filters).unwrap();
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileName = `exported_notifications_${new Date()
          .toISOString()
          .replace(/:/g, "-")}.xlsx`;

        saveAs(blob, fileName);
        openSweetAlert({
          title: "Notification exported Successfully!",
        });
      },
    });
  };

  return (
    <>
      <BreadCrumbs pageName="notificationList" pageTitle="Notification List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={notificationsColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Notifications Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Notifications;
