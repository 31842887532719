import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Eye, Edit, Trash2 } from "react-feather";
import PermissionLink from "@src/components/shared/PermissionLink";
import { Button } from "reactstrap";
import PermissionButton from "@src/components/shared/PermissionButton";

const messageTypeBodyTemplate = (rowData) => {
  return <span className="text-uppercase">{rowData.messageTypeCode}</span>;
};

const messageTypeFilterTemplate = (options, messageTypeList) => {
  return (
    <Dropdown
      value={options.value}
      options={messageTypeList?.map((item) => item.code)}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={messageTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const messageTypeItemTemplate = (option) => {
  return <span className="text-uppercase">{option}</span>;
};

const actionsBodyTemplate = (row, handleDeleteClick) => {
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <PermissionLink
          title="View"
          style={{ padding: ".75rem 1.25rem" }}
          to={`/sita-messages/messageTemplates/${row.id}`}
          permission="sim.message-template.view"
        >
          {" "}
          <Eye size={16} className="text-body" />
        </PermissionLink>
        <PermissionLink
          title="Edit"
          style={{ padding: ".75rem 1.25rem" }}
          to={`/sita-messages/messageTemplates/edit/${row.id}`}
          permission="sim.message-template.update"
        >
          {" "}
          <Edit size={16} className="text-body" />
        </PermissionLink>
        <PermissionButton
          className="btn-icon"
          color="flat-danger"
          onClick={() => {
            handleDeleteClick({ ...row });
          }}
          permission="sim.message-template.delete"
        >
          <Trash2 size={16} />
        </PermissionButton>
      </div>
    </>
  );
};

const messageTemplatesColumns = (handleDeleteClick, messageTypeList) => {
  return [
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Country",
      filter: true,
      field: "country",
      sortable: false,
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "Message Type",
      filter: true,
      field: "messageTypeCode",
      sortable: false,
      body: messageTypeBodyTemplate,
      filterElement: (rowData) =>
        messageTypeFilterTemplate(rowData, messageTypeList),
      showFilterMatchModes: false,
      filterField: "messageType.code",
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: (rowData) => actionsBodyTemplate(rowData, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default messageTemplatesColumns;
