import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useLazyGetMessageTypeQuery } from "@src/redux/MessageTypes";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { TextInput, CheckboxInput } from "@src/components/inputs";
import { faHashtag, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectInput } from "@src/components/inputs";
import { useConfigureMessageTypesMutation } from "@src/redux/MessageTypes";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { Plus } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Configurations = ({ visible, toggle, messageTypeId }) => {
  const [messageType, setMessageType] = useState(null);
  const [getMessageType] = useLazyGetMessageTypeQuery();
  const [configureMessageTypes] = useConfigureMessageTypesMutation();
  const [sendingTimesCount, setSendingTimesCount] = useState(1);

  const sendingTriggerOptions = [
    { label: "Before Departure", value: "beforeDeparture" },
    { label: "After Departure", value: "afterDeparture" },
  ];

  const validationSchema = yup.object().shape({
    isActive: yup.boolean(),
    enableMultipart: yup.boolean(),
    maxCharactersPerPart: yup.number().when("enableMultipart", {
      is: true,
      then: () =>
        yup
          .number()
          .min(1, "Must be greater than 0 when multipart is enabled")
          .required("Required when multipart is enabled"),
      otherwise: () => yup.number().nullable(),
    }),
    sendingTimes: yup.array().of(
      yup.object().shape({
        trigger: yup.string().required("Sending trigger is required"),
        hours: yup
          .number()
          .required("Hours is required")
          .min(1, "Hours must be greater than or equal to 1"),
      })
    ),
  });

  const formMethods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isActive: false,
      enableMultipart: false,
      maxCharactersPerPart: 0,
      sendingTimes: [{ trigger: undefined, hours: 0 }],
    },
  });

  const enableMultipart = formMethods.watch("enableMultipart");
  const sendingTimes = formMethods.watch("sendingTimes");

  useEffect(() => {
    const fetchMessageType = async () => {
      const data = await getMessageType(messageTypeId).unwrap();
      setMessageType(data);
      setSendingTimesCount(data?.sendingTimes?.length || 1);
      formMethods.reset({
        isActive: data?.isActive || false,
        enableMultipart: data?.enableMultipart || false,
        maxCharactersPerPart: data?.maxCharactersPerPart || 0,
        sendingTimes: data?.sendingTimes?.map((time) => ({
          id: time.id,
          trigger: time.trigger,
          hours: time.hours,
        })) || [{ trigger: undefined, hours: 0 }],
      });
    };
    if (messageTypeId) {
      fetchMessageType();
    }
  }, [visible, formMethods, messageTypeId]);

  const handleFormSubmit = async (formData) => {
    await configureMessageTypes({
      id: messageTypeId,
      isActive: formData.isActive,
      enableMultipart: formData.enableMultipart,
      maxCharactersPerPart: formData.maxCharactersPerPart,
      sendingTimes: formData.sendingTimes,
    })
      .unwrap()
      .then(() => {
        SuccessToast("Message configuration updated Successfully!");
        toggle(false);
      })
      .catch((rejected) => {});
  };

  const addNewSendingTime = () => {
    const currentSendingTimes = formMethods.getValues("sendingTimes");
    formMethods.setValue("sendingTimes", [
      ...currentSendingTimes,
      { trigger: undefined, hours: 0 },
    ]);
    setSendingTimesCount((prev) => prev + 1);
  };

  const removeSendingTime = (index) => {
    const currentSendingTimes = formMethods.getValues("sendingTimes");
    if (currentSendingTimes.length > 1) {
      formMethods.setValue(
        "sendingTimes",
        currentSendingTimes.filter((_, i) => i !== index)
      );
      setSendingTimesCount((prev) => prev - 1);
    }
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid modal-lg">
      <ModalHeader toggle={toggle}>
        Message Type ({messageType?.code}) Configurations
      </ModalHeader>
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalBody>
            <Row>
              <Col className="mb-2">
                <CheckboxInput label="Is Active?" name="isActive" />
              </Col>
            </Row>
            <Row>
              <Col className="mb-2">
                <CheckboxInput
                  className="mb-2"
                  label="Enable Multipart?"
                  name="enableMultipart"
                />
                {enableMultipart && (
                  <TextInput
                    label="Max Characters Per Part"
                    name="maxCharactersPerPart"
                    icon={faHashtag}
                  />
                )}
              </Col>
            </Row>
            <hr />
            <div className="sending-times-container">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="mb-0">Sending Times</h6>
                <Button.Ripple
                  className="btn-icon"
                  color="primary"
                  onClick={addNewSendingTime}
                >
                  <Plus size={14} />
                  <span className="align-middle ms-25">Add New</span>
                </Button.Ripple>
              </div>

              {Array.from({ length: sendingTimesCount }).map((_, index) => (
                <Row key={index} className="mb-2 align-items-end">
                  <Col md={5}>
                    <SelectInput
                      label="Trigger"
                      name={`sendingTimes.${index}.trigger`}
                      options={sendingTriggerOptions}
                    />
                  </Col>
                  <Col md={5}>
                    <TextInput
                      label="Hours"
                      name={`sendingTimes.${index}.hours`}
                      icon={faHashtag}
                    />
                  </Col>
                  <Col md={2}>
                    {sendingTimesCount > 1 && (
                      <Button.Ripple
                        className="btn-icon"
                        color="danger"
                        outline
                        onClick={() => removeSendingTime(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} size="sm" />
                      </Button.Ripple>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default Configurations;
