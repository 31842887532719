import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { formatDateTime } from "@src/utility/Utils";
import { Eye } from "react-feather";
import StatusTag from "../../components/Messages/StatusTag";
import { format } from "date-fns";
import PermissionLink from "@src/components/shared/PermissionLink";
import SendNavitaireMessage from "./SendNavitaireMessage";
import { Button } from "reactstrap";

const messageStatuses = ["pending", "sent", "fail"];

const statusBodyTemplate = (rowData) => {
  return <StatusTag status={rowData.sent ? "sent" : "pending"} />;
};

const statusItemTemplate = (option) => {
  return <StatusTag status={option} />;
};

const statusFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={statusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const dateFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) => {
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
      }}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
};

const stdBodyTemplate = (rowData) => {
  return formatDateTime(new Date(rowData.std));
};

const messageTimeTemplate = (rowData) => {
  return formatDateTime(new Date(rowData.timeStamp));
};

export const navitaireMessagesColumns = (toggleCanvas) => {
  const actionsBodyTemplate = (row) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* <Button.Ripple
          className="btn-icon"
          color="flat-dark"
          onClick={() => {
            toggleCanvas();
          }}
        >
          <Eye size={16} />
        </Button.Ripple> */}
        <SendNavitaireMessage messageId={row.id} />
      </div>
    );
  };

  return [
    {
      header: "Flight",
      //filter: true,
      field: "flight_Number",
      sortable: false,
      // filterField: "flight.flightNo",
    },
    {
      header: "STD",
      //filter: true,
      field: "std",
      sortable: false,
      dataType: "date",
      body: stdBodyTemplate,
    },
    {
      header: "Station",
      //filter: true,
      field: "station",
      sortable: false,
      //filterField: "flight.arrival",
    },
    {
      header: "Message Type",
      //filter: true,
      field: "messageType",
      sortable: false,
      //filterField: "flight.departure",
    },
    {
      header: "Part",
      //filter: true,
      field: "part",
      sortable: false,
      //filterField: "flight.arrival",
    },
    {
      header: "Recipents",
      //filter: true,
      field: "recipent_Emails",
      sortable: false,
    },
    {
      header: "Message Status",
      //filter: true,
      field: "sent",
      sortable: false,
      body: statusBodyTemplate,
    },
    {
      header: "Message Time",
      //filter: true,
      field: "timeStamp",
      sortable: false,
      dataType: "date",
      body: messageTimeTemplate,
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: actionsBodyTemplate,
    },
  ];
};
