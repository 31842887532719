import React from "react";
import { Button as PrimButton } from "primereact/button";
import { Button } from "reactstrap";
import { DownloadCloud } from "react-feather";
import { saveAs } from "file-saver";
import { Download } from "react-feather";
import { useLazyDownloadLoadSheetQuery } from "../../redux/loadsheets";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const FileDownloader = ({ data, isIcon = false }) => {
  const [downloadLoadSheet] = useLazyDownloadLoadSheetQuery();

  const downloadFile = async () => {
    const response = await downloadLoadSheet(data.id)
      .unwrap()
      .then(() => {
        const blob = new Blob([response], { type: response.type });
        saveAs(blob, data.fileName);
        SuccessToast(`Load-Sheet downloaded Successfully!`);
      })
      .catch((rejected) => {});
  };

  if (isIcon)
    return (
      <PrimButton
        tooltip="Download"
        tooltipOptions={{ position: "top" }}
        text
        onClick={downloadFile}
      >
        <Download className="font-medium-3 text-body" />
      </PrimButton>
    );

  return (
    <Button
      className="me-1"
      type="button"
      severity="secondary"
      onClick={downloadFile}
    >
      <DownloadCloud size={18} />
      &nbsp; Download File
    </Button>
  );
};

export default FileDownloader;
