import {
  Home,
  MessageSquare,
  FileText,
  List,
  Settings,
  Send,
  Mail,
  Bookmark,
  Bell,
  Sliders,
  UploadCloud,
  Layout,
} from "react-feather";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBoxesPacking,
  faUserTie,
  faTrowelBricks,
} from "@fortawesome/free-solid-svg-icons";

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/sita-messages/home",
  },
  {
    id: "flights",
    title: "Flights",
    icon: <Send size={20} />,
    navLink: "/sita-messages/flights",
    permissions: "sim.flights.view",
  },
  {
    id: "messages",
    title: "Messages",
    icon: <MessageSquare size={20} />,
    children: [
      {
        id: "prl",
        title: "PRL Messages",
        icon: <FontAwesomeIcon icon={faUsers} style={{ fontSize: "1.3rem" }} />,
        navLink: "/sita-messages/prl-messages",
        permissions: "sim.prl.view",
      },
      {
        id: "ldm",
        title: "LDM Messages",
        icon: (
          <FontAwesomeIcon
            icon={faBoxesPacking}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/sita-messages/ldm-messages",
        permissions: "sim.ldm.view",
      },
      {
        id: "Apis",
        title: "APIS Messages",
        icon: (
          <FontAwesomeIcon icon={faUserTie} style={{ fontSize: "1.3rem" }} />
        ),
        navLink: "/sita-messages/apis-messages",
        permissions: "sim.apis.view",
      },
      {
        id: "Pnl-Adl",
        title: "PNL/ADL Messages",
        icon: (
          <FontAwesomeIcon
            icon={faTrowelBricks}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/sita-messages/navitaire-messages",
        permissions: "sim.pnl.view",
      },
      // {
      //   id: "Pnl",
      //   title: "PNL Generators",
      //   icon: (
      //     <FontAwesomeIcon
      //       icon={faTrowelBricks}
      //       style={{ fontSize: "1.3rem" }}
      //     />
      //   ),
      //   navLink: "/sita-messages/pnl-message-generators",
      //   permissions: "sim.pnl.view",
      // },
    ],
  },
  {
    id: "loadSheets",
    title: "Load Sheets",
    icon: <FileText size={20} />,
    children: [
      {
        id: "loadSheetsList",
        title: "List",
        icon: <List size={12} />,
        navLink: "/sita-messages/load-sheets",
        permissions: "sim.load-sheet.view",
      },
      {
        id: "uploadLoadSheet",
        title: "upload",
        icon: <UploadCloud size={12} />,
        navLink: "/sita-messages/load-sheets/upload",
        permissions: "sim.load-sheet.upload",
      },
      {
        id: "stationMails",
        title: "Station Mails",
        icon: <Mail size={12} />,
        navLink: "/sita-messages/station-mails",
        permissions: "sim.station-mail.view",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    icon: <Settings size={20} />,
    children: [
      {
        id: "messageTypes",
        title: "Message Types",
        icon: <FileText size={20} />,
        navLink: "/sita-messages/messageTypes",
      },
      {
        id: "messagetemplates",
        title: "Message Templates",
        icon: <Layout size={20} />,
        navLink: "/sita-messages/messageTemplates",
        permissions: "sim.message-template.view",
      },
      {
        id: "messageSenders",
        title: "Message Senders",
        icon: <Send size={20} />,
        navLink: "/sita-messages/messageSenders",
        permissions: "sim.message-sender.view",
      },
      {
        id: "apisSettings",
        title: "Apis Settings",
        icon: <Sliders size={20} />,
        navLink: "/sita-messages/apisSettings",
        permissions: "sim.apis-settings.view",
      },
      {
        id: "messageAddresses",
        title: "Message Addresses",
        icon: <Bookmark size={20} />,
        navLink: "/sita-messages/messageAddresses",
        permissions: "sim.message-address.view",
      },
    ],
  },
];
