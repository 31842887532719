import toast from "react-hot-toast";

export const SuccessToast = (
  message = "Operation successful!",
  duration = 5000
) => {
  toast.success(message, {
    style: {
      border: "1px solid #00aba8",
      padding: "16px",
      color: "#495057",
    },
    iconTheme: {
      primary: "#00aba8",
      secondary: "#fff",
    },
    duration,
  });
};
