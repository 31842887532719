import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Badge } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { Edit, Trash2 } from "react-feather";

const StationMailsColumns = (handleEditClick, handleDeleteClick) => {
  const ActivationStatusBodyTemplate = ({ active }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getActivationStatusIcon(active.toString())}
        className={getActivationStatusClass(active.toString())}
        style={{ fontSize: "1.3rem" }}
        aria-hidden="true"
      />
      <span className="sr-only">
        {active === "true" ? "Active" : "Inactive"}
      </span>
    </span>
  );
  const getActivationStatusIcon = (active) =>
    active === "true" ? faCircleCheck : faCircleXmark;

  const getActivationStatusClass = (active) =>
    active === "true" ? "text-success" : "text-danger";

  const ActivationStatuses = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const ActivationStatusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={ActivationStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={ActivationStatusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
  const ActivationStatusItemTemplate = ({ label, value }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getActivationStatusIcon(value)}
        className={getActivationStatusClass(value)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {label}
    </span>
  );
  const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
    return (
      <div className="justify-content-center flex-column text-center">
        <Button.Ripple
          className="btn-icon"
          color="flat-dark"
          onClick={() => {
            handleEditClick({ ...rowData });
          }}
        >
          <Edit size={16} />
        </Button.Ripple>
        <Button.Ripple
          className="btn-icon"
          color="flat-danger"
          onClick={() => {
            handleDeleteClick({ ...rowData });
          }}
        >
          <Trash2 size={16} />
        </Button.Ripple>
      </div>
    );
  };

  const MailsBodyTemplate = ({ mails }) =>
    mails.split(";").map((mail, index) => (
      <Fragment key={index}>
        <Badge color="light-secondary" className="rounded-pill p-15">
          {mail.trim()}
        </Badge>
        {index < mails.split(";").length - 1 && "     "}
      </Fragment>
    ));

  return [
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "ICAO",
      filter: true,
      field: "icao",
      sortable: false,
    },
    {
      header: "Active",
      filter: true,
      field: "active",
      sortable: false,
      body: ActivationStatusBodyTemplate,
      filterElement: ActivationStatusFilterTemplate,
      showFilterMatchModes: false,
      dataType: "boolean",
      bodyClassName: "text-center",
    },
    {
      header: "Mails",
      filter: true,
      field: "mails",
      sortable: false,
      body: MailsBodyTemplate,
      style: { maxWidth: "10%" },
      bodyStyle: { maxWidth: "10%" },
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default StationMailsColumns;
