import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faUsers,
  faUserTie,
  faBoxesPacking,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { Edit, Settings } from "react-feather";

const actionsBodyTemplate = (
  rowData,
  handleEditClick,
  configureMessageType
) => {
  return (
    <>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <Button
          tooltip="Edit"
          tooltipOptions={{ position: "top" }}
          severity="secondary"
          text
          onClick={() => {
            handleEditClick({ ...rowData });
          }}
        >
          <span className="text-capitalize">
            <Edit size={16} className="text-body" />
          </span>
        </Button>
        <Button
          tooltip="Configurations"
          tooltipOptions={{ position: "top" }}
          severity="secondary"
          text
          onClick={() => {
            configureMessageType(rowData.id);
          }}
        >
          <span className="text-capitalize">
            <Settings size={16} className="text-body" />
          </span>
        </Button>
      </div>
    </>
  );
};

const messageTypesColumns = (handleEditClick, configureMessageType) => {
  return [
    {
      header: "Code",
      filter: true,
      field: "code",
      sortable: false,
    },
    {
      header: "Name",
      filter: true,
      field: "name",
      sortable: false,
    },
    {
      header: "Description",
      filter: true,
      field: "description",
      sortable: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, configureMessageType),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default messageTypesColumns;
