import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, Input, InputGroup } from "reactstrap";

function ReadonlyInput({ label, name, color = "primary", icon, ...props }) {
  return (
    <>
      {label && (
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      )}
      <InputGroup>
        {icon && (
          <span className={`p-inputgroup-addon text-${color}`}>
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        <Input key={props.defaultValue} disabled={true} {...props} />
      </InputGroup>
    </>
  );
}

export default ReadonlyInput;
