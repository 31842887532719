import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { useResendMessageMutation } from "@src/redux/messages";
import { MultiSelectInput } from "@src/components/inputs";

const ResendMessageModel = ({ visible, toggle, messageId }) => {
  const [resendMessage, { isLoading, data, error }] =
    useResendMessageMutation();
  const sendingMethodsOptions = [
    { label: "Sita", value: 0 },
    { label: "Mail", value: 1 },
  ];
  const apisSettingsSchema = yup.object().shape({
    sendingMethods: yup.array().of(yup.string()).notRequired(),
  });
  useEffect(() => {
    if (visible) {
      formMethods.reset({
        sendingMethods: [],
      });
    }
  }, [visible, formMethods]);
  const formMethods = useForm({
    resolver: yupResolver(apisSettingsSchema),
    mode: "onSubmit",
    defaultValues: {
      sendingMethods: [],
    },
  });

  const handleFormSubmit = async (formData) => {
    if (!error) {
      await resendMessage({
        Id: messageId,
        sendingMethods: formData.sendingMethods,
      });
      toggle(false);
      SuccessToast("Message Resent successfully!");
    }
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Resend Message</ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <MultiSelectInput
                placeholder="Select (Optional)"
                label="Sending Methods"
                name="sendingMethods"
                options={sendingMethodsOptions}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Resend
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ResendMessageModel;
