import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SelectInput } from "@src/components/inputs";
import {
  useUpdateMessageSenderMutation,
  useAddMessageSenderMutation,
} from "@src/redux/messageSenders";
import CheckboxInput from "@src/components/inputs/CheckboxInput";
import { useListMessageTypesQuery } from "@src/redux/MessageTypes";

const MessageSenderModal = ({
  visible,
  toggle,
  editData,
  setEditData,
  mode,
}) => {
  const [updateMessageSender] = useUpdateMessageSenderMutation();
  const [addMessageSender] = useAddMessageSenderMutation();
  const { data: messageTypeList } = useListMessageTypesQuery();

  const sendingMethodOptions = [
    { label: "Sita", value: "sita" },
    { label: "Email", value: "email" },
  ];

  const messageSenderSchema = yup.object().shape({
    messageTypeId: yup.string().required("Message type is required"),
    sendingMethod: yup.string().required("Sending method is required"),
    primary: yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageSenderSchema),
    mode: "onSubmit",
    defaultValues: {
      messageTypeId: "",
      sendingMethod: "",
      primary: false,
    },
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          messageTypeId: "",
          sendingMethod: "",
          primary: false,
        });
      } else {
        formMethods.reset({
          messageTypeId: editData?.messageTypeId || "",
          sendingMethod: editData?.sendingMethod || "",
          primary: editData?.primary || false,
        });
      }
    }
  }, [editData, visible, formMethods, mode]);

  const handleFormSubmit = async (formData) => {
    if (mode === "update" && editData) {
      await updateMessageSender({
        id: editData.id,
        messageTypeId: formData.messageTypeId,
        sendingMethod: formData.sendingMethod,
        primary: formData.primary,
      });
    } else {
      await addMessageSender({
        messageTypeId: formData.messageTypeId,
        sendingMethod: formData.sendingMethod,
        primary: formData.primary,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Message Sender
          </ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <SelectInput
                label="Message Type"
                name="messageTypeId"
                options={
                  messageTypeList?.map((type) => ({
                    value: type.id,
                    label: type.code,
                  })) || []
                }
              />
            </div>
            <div className="mb-2">
              <SelectInput
                label="Sending Method"
                name="sendingMethod"
                options={sendingMethodOptions}
              />
            </div>
            <CheckboxInput label="Is Primary?" name="primary" />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default MessageSenderModal;
