import React from "react";
import { Button as PrimButton } from "primereact/button";
import { Button } from "reactstrap";
import { DownloadCloud } from "react-feather";
import { saveAs } from "file-saver";
import { Download } from "react-feather";
import { useLazyDownloadLoadSheetQuery } from "../../redux/loadsheets";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";

const FileDownloader = ({ data, isIcon = false }) => {
  const [downloadLoadSheet] = useLazyDownloadLoadSheetQuery();

  const downloadFile = async () => {
    const response = await downloadLoadSheet(data.id).unwrap();
    const blob = new Blob([response], { type: response.type });
    saveAs(blob, data.fileName);
    openSweetAlert({
      title: `Load-Sheet downloaded Successfully!`,
    });
  };

  if (isIcon)
    return (
      <PrimButton
        tooltip="Download"
        tooltipOptions={{ position: "top" }}
        text
        onClick={downloadFile}
      >
        <Download className="font-medium-3 text-body" />
      </PrimButton>
    );

  return (
    <Button
      className="me-1"
      type="button"
      severity="secondary"
      onClick={downloadFile}
    >
      <DownloadCloud size={18} />
      &nbsp; Download File
    </Button>
  );
};

export default FileDownloader;
