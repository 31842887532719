import MessageTypes from ".";
import Configurations from "./configurations";

const MessageTypeRoutes = [
  {
    path: "/messageTypes",
    index: true,
    element: <MessageTypes />,
  },
];

export default MessageTypeRoutes;
