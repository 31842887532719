import { Dropdown } from "primereact/dropdown";
import {
  faBoxesPacking,
  faUsers,
  faUserTie,
  faEnvelope,
  faNoteSticky,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { Edit, Trash2 } from "react-feather";

const messageTypes = ["ldm", "prl", "apis"];

const getMessageType = (type) => {
  switch (type) {
    case "ldm":
      return faBoxesPacking;
    case "prl":
      return faUsers;
    case "apis":
      return faUserTie;
  }
};

const sendingMethod = ["sita", "email"];

const getSendingMethod = (type) => {
  switch (type) {
    case "sita":
      return faNoteSticky;
    case "email":
      return faEnvelope;
  }
};

const messageTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(rowData.messageType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.messageType}
    </span>
  );
};

const messageTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={messageTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const messageTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const sendingMethodBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getSendingMethod(rowData.sendingMethod)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.sendingMethod}
    </span>
  );
};

const sendingMethodFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={sendingMethod}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={sendingMethodItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const sendingMethodItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getSendingMethod(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};
const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
  return (
    <div className="justify-content-center flex-column text-center">
      <Button.Ripple
        className="btn-icon"
        color="flat-dark"
        onClick={() => {
          handleEditClick({ ...rowData });
        }}
      >
        <Edit size={16} />
      </Button.Ripple>
      <Button.Ripple
        className="btn-icon"
        color="flat-danger"
        onClick={() => {
          handleDeleteClick({ ...rowData });
        }}
      >
        <Trash2 size={16} />
      </Button.Ripple>
    </div>
  );
};

const PrimaryStatuses = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const getPrimaryStatusIcon = (primary) =>
  primary === "true" ? faCircleCheck : faCircleXmark;

const getPrimaryStatusClass = (primary) =>
  primary === true ? "text-success" : "text-danger";

const PrimaryStatusBodyTemplate = ({ primary }) => (
  <span className="text-capitalize">
    <FontAwesomeIcon
      icon={getPrimaryStatusIcon(primary)}
      className={getPrimaryStatusClass(primary)}
      style={{ fontSize: "1.3rem" }}
      aria-hidden="true"
    />
    <span className="sr-only">{primary === true ? "Yes" : "No"}</span>
  </span>
);

const PrimaryStatusFilterTemplate = (options) => (
  <Dropdown
    value={options.value}
    options={PrimaryStatuses}
    onChange={(e) => options.filterCallback(e.value, options.index)}
    itemTemplate={PrimaryStatusItemTemplate}
    placeholder="Select One"
    className="p-column-filter"
    showClear
  />
);

const PrimaryStatusItemTemplate = ({ label, value }) => (
  <span className="text-capitalize">
    <FontAwesomeIcon
      icon={getPrimaryStatusIcon(value)}
      className={getPrimaryStatusClass(value)}
      style={{ fontSize: "1.3rem" }}
    />{" "}
    {label}
  </span>
);

const messageSendersColumns = (handleEditClick, handleDeleteClick) => {
  return [
    {
      header: "Message Type",
      filter: true,
      field: "messageType",
      sortable: false,
      body: messageTypeBodyTemplate,
      filterElement: messageTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Sending Method",
      filter: true,
      field: "sendingMethod",
      sortable: false,
      body: sendingMethodBodyTemplate,
      filterElement: sendingMethodFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Is Primary",
      filter: true,
      field: "primary",
      sortable: false,
      body: PrimaryStatusBodyTemplate,
      filterElement: PrimaryStatusFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
      bodyStyle: { width: "10%" },
    },
  ];
};

export default messageSendersColumns;
